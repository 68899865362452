<template>
    <v-app>

        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-state="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12">
                                <h4> Notification</h4>
                            </div>
                            <v-skeleton-loader
                                    v-if="is_loading"
                                    v-bind="attrs"
                                    type="list-item-avatar, list-item-avatar, list-item-avatar"
                            ></v-skeleton-loader>
                            <div v-if="!is_loading && notifications.length==0"  class="col-12 d-flex flex-column align-items-center  p-3">
                                <img width="50%" src="@/assets/media/images/no-data-found.png">
                                <h5 class="mt-10">No New Notification.</h5>
                            </div>
                            <div class="col-12" v-if="!is_loading && notifications && notifications.length">

                            <v-list three-line>
                                <template v-for="(item, index) of notifications" >
                                    <v-list-item

                                            :key="item.title"
                                    >
                                        <v-list-item-avatar tile>
                                            <v-img   v-if="item.image" :src="item.image_path['real']"></v-img>
                                            <v-icon
                                                    v-else
                                                    class="grey lighten-1"
                                                    dark
                                            >
                                                mdi-bell
                                            </v-icon>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{item.title}}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="cursor-pointer"
                                                    @click.prevent="viewNotification(item)" v-html="item.description"></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="item.notified_at">
                                            {{item.notified_at | moment("from", "now")}}
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </template>
                            </v-list>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-dialog v-model="notification_dialog" max-width="800" scrollable>
            <v-card v-if="notification">
                <v-card-title>
                    {{notification.title}} <br>
                    {{notification.description}}
                    <span class="font-size-sm" v-if="notification.notified_at">
                                                      {{notification.notified_at | moment("from", "now")}}
                                                </span>
                    <v-img max-height="400" contain   v-if="notification.image" :src="notification.image_path['real']"></v-img>
                </v-card-title>
                <v-card-text>

                </v-card-text>
                <v-card-actions v-if="notification.link">
                    <v-btn block :to="notification.link"           class="btn btn-primary text-white"
                           target="_blank">
                        View
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-app>
</template>

<script>
    import {API_URL} from "../../../common/config";
    import NotificationService from "@/core/services/notification/NotificationService";
    const notificationService = new NotificationService();
    export default {
        name: "Notification",
        data() {
            return {
                notifications: [],
                notification: null,
                notification_dialog: false,
                attrs: false,
                is_loading: false
            }
        },
        mounted() {
            this.getNotifications()
        },
        methods: {
            getNotifications() {
                this.is_loading = true;
                notificationService.all().then((response) => {
                    this.notifications = response.data.data;
                }).catch(error=>{

                }).finally(()=>{
                    this.is_loading = false;

                })
            },
            formatType(type) {
                    return type.split('-').join(' ').toUpperCase();
            },
            viewNotification(notification) {
             this.notification_dialog = true;
             this.notification = notification;
             notificationService.markAsRead(this.notification.id).then(response=>{
                 console.log(response)
             }).catch(error=>{
                 console.log(error)
             }).finally(()=>{

             })
            },
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        }
    }
</script>

<style scoped>

</style>